import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "วัตถุดิบ"
    }}>{`วัตถุดิบ`}</h3>
    <p>{`วัตถุดิบภายในเกม จะแบ่งออกเป็น 2 ประเภท`}</p>
    <ul>
      <li parentName="ul">{`เมล็ดพันธ์ุ : ใช้สำหรับปลูกพืช สร้างผลผลิตจากพืช`}</li>
      <li parentName="ul">{`อุปกรณ์เลี้ยงสัตว์ : ใช้สำหรับเก็บผลผลิตจากสัตว์ `}</li>
    </ul>
    <p><img alt="mat" src={require("./public/images/mat.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      